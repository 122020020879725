<template>
  <div>

    <img :src="article_part.thumb" alt="" style="width: 100%;" v-if="article_part.type == 'image'"/>

    <p v-if="article_part.thumb_tags">{{ article_part.thumb_tags }}</p>

    <video class="responsive-video mb-4" controls v-if="article_part.type == 'video'">
      <source :src="article_part.video_url" type="video/mp4">
    </video>

    <div class="player-play mb-4" v-if="article_part.type == 'audio'">
      <div class="player-play__thumb"
           :style="{ backgroundImage: `url(${article_part.top_thumb_url})` }"
      ></div>
      <div class="player-play__info">
        <h3 class="player-play__name">{{ article_part.audio_name }}</h3>
        <div class="player-play__play">
          <a :href="article_part.audio_url"
             :data-name="article_part.audio_name"
             :data-thumb="article_part.top_thumb_url"
             :data-url="article_part.top_url+'?play='+article_part.id_top"
             :id="article_part.id_top"
             class="audio-btn"
             v-on:click="playAudio"

          >
            <i class="material-icons">play_arrow</i>
          </a>
        </div>
      </div>
    </div>


    <p class="article__text" v-if="article_part.type == 'text'" v-html="article_part.text"></p>


  </div>


</template>

<script>

export default {
  name: "ShowPart",
  props: ['article_part'],
  methods: {
    playAudio: function (e) {

      e.preventDefault();

      let audioPlayer = this.$root.$refs.audioPlayer;
      let article_part = this.article_part;

      audioPlayer.set(article_part.audio_url, article_part.top_name, article_part.top_thumb_url);
      audioPlayer.play();
      audioPlayer.show();

    }
  },
  created() {


    let domain = "https://radiokrakowkultura.pl/api/"
    let domain_new = "https://off.radiokrakow.pl/"

    // if (this.article_part.audio_url)
    //   this.article_part.audio_url = domain_new + this.article_part.audio_url.substr(domain.length)
    //
    // if (this.article_part.top_thumb_url)
    //   this.article_part.top_thumb_url = domain_new + this.article_part.top_thumb_url.substr(domain.length)

    console.log(this.article_part)

  }


}
</script>

<style scoped>

</style>