<template>

  <ul class="user-list user-list-min">
    <li class="user-list__item" v-for="user in users_show">
      <router-link :to=" '/'+ user.url_full" class="user-list__link">
        <div class="user-list__thumb" :style="{ backgroundImage: `url(${user.avatar_url})` }"></div>

        <h4 class="user-list__name" v-if="user.account_type == 'private' || user.firstname">{{user.firstname}} {{user.lastname}}</h4>
        <h4 class="user-list__name" v-else-if="user.account_type == 'company' || user.name != ''">{{user.name}}</h4>
      </router-link>

    </li>
  </ul>
</template>

<script>
import CoreArray from "@/helpers/array.js";

export default {
  name: "UserSmallList",
  props: ['users'],
  data() {
    return {
      'users_show': []
    }
  },
  mounted() {

    let domain = "https://off.radiokrakow.pl/api/"
    let domain_new = "https://off.radiokrakow.pl/"

    this.users_show = this.users
    // this.users_show = CoreArray.changeDomain(this.users,'avatar_url',domain, domain_new)
    this.users_show = CoreArray.changeDomain(this.users_show,'url_full','ludzie-radia', 'zespol')

  }
}
</script>

<style scoped>

</style>